import React from 'react'

const NotFoundPage = () => (
  <div className="row" style={{ marginTop: 96 }}>
    <div className="col-sm-12 col-md-10 offset-lg-1">
      {/* TODO: translate */}
      <h1>Not found</h1>
      <p>
        Please feel free to contact us if there is anything we can help you
        with.
      </p>
    </div>
  </div>
)

export default NotFoundPage
